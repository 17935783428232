export const STAGE_DATA = [
  '',
  '',
  'FOUNTAIN_OF_DREAMS',
  'POKEMON_STADIUM',
  'PEACHS_CASTLE',
  'KONGO_JUNGLE',
  'BRINSTAR',
  'CORNERIA',
  'YOSHIS_STORY',
  'ONETT',
  'MUTE_CITY',
  'RAINBOW_CRUISE',
  'JUNGLE_JAPES',
  'GREAT_BAY',
  'HYRULE_TEMPLE',
  'BRINSTAR_DEPTHS',
  'YOSHIS_ISLAND',
  'GREEN_GREENS',
  'FOURSIDE',
  'MUSHROOM_KINGDOM',
  'MUSHROOM_KINGDOM_2',
  '',
  'VENOM',
  'POKE_FLOATS',
  'BIG_BLUE',
  'ICICLE_MOUNTAIN',
  'ICETOP',
  'FLAT_ZONE',
  'DREAMLAND',
  'YOSHIS_ISLAND_N64',
  'KONGO_JUNGLE_N64',
  'BATTLEFIELD',
  'FINAL_DESTINATION',
];

export const CHAR_DATA = [
  'Mario',
  'Fox',
  'Cpt. Falcon',
  'DK',
  'Kirby',
  'Bowser',
  'Link',
  'Sheik',
  'Ness',
  'Peach',
  'Ice Climbers',
  'Nana',
  'Pikachu',
  'Samus',
  'Yoshi',
  'Jigglypuff',
  'Mewtwo',
  'Luigi',
  'Marth',
  'Zelda',
  'Young Link',
  'Dr. Mario',
  'Falco',
  'Pichu',
  'Mr. G&W',
  'Ganondorf',
  'Roy',
];
